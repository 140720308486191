import {
  CAR_CERTIFICATION_ERROR,
  CAR_CERTIFICATION_LOADING,
  CAR_CERTIFICATION_SUCCESS,
} from '../types/carCertificationTypes'

const initialState = {
  loading: false,
  error: false,
  carCertification: {},
}

const carCertificationReducer = (state: any = initialState, data: any) => {
  switch (data.type) {
    case CAR_CERTIFICATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case CAR_CERTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case CAR_CERTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    default:
      return state
  }
}

export default carCertificationReducer
