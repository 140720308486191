export const FETCH_CONTACT_INFORMATION_LOADING =
  'FETCH_CONTACT_INFORMATION_LOADING'
export const FETCH_CONTACT_INFORMATION_ERROR = 'FETCH_CONTACT_INFORMATION_ERROR'
export const FETCH_CONTACT_INFORMATION_SUCCESS =
  'FETCH_CONTACT_INFROMATION_SUCCESS'

export interface IContactInformationInitialType {
  loading: boolean
  error: boolean
  contactInformation: IContactInformationType
}
export interface IContactInformationType {
  contact: any
  _id: string
  fullName: string
  email: string
  phoneNumber: any
  address: any
  socialLinks: string
  location: string
}
