export const FETCH_CAR_MODELS_LOADING = 'FETCH_CAR_MODELS_LOADING';
export const FETCH_CAR_MODELS_SUCCESS = 'FETCH_CAR_MODELS_SUCCESS';
export const FETCH_CARS_MODELS_ERROR = 'FETCH_CAR_MODELS_ERROR';
export const FETCH_CAR_AVAILABLE_MODEL = 'FETCH_CAR_AVAILABLE_MODEL';

export interface ICarModelInitialType {
    loading: boolean;
    error: boolean;
    carModels: Array<ICarModelType>;
    carAvilableModel: Array<ICarModelType>;
}

export interface ICarModelType {
    _id: string;
    name: string;
    model: number;
    year: number;
    slug?: string;
}
