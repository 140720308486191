import {
    CREATE_QUESTIONNAIRE_ERROR,
    CREATE_QUESTIONNAIRE_LOADING,
    CREATE_QUESTIONNAIRE_SUCCESS,
    FETCH_ALL_QUESTIONNAIRE_SUCCESS,
    QUESTIONNAIRE_ERROR,
    QUESTIONNAIRE_LOADING,
    QUESTIONNAIRE_SUCCESS
} from '../types/questionnaireTypes';

const initialState = {
    loading: false,
    error: false,
    faqs: [],
    allFaqs: []
};
const initialStateCreate = {
    loading: false,
    error: false,
    faqs: [],
    allFaqs: []
};

const faqsReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case QUESTIONNAIRE_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case QUESTIONNAIRE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                faqs: []
            };
        case QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                faqs: data.payload
            };

        case FETCH_ALL_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                loading: false,
                allFaqs: data.payload
            };

        default:
            return state;
    }
};
export default faqsReducer;

export const createFaqReducer = (
    state: any = initialStateCreate,
    data: any
) => {
    switch (data.type) {
        case CREATE_QUESTIONNAIRE_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case CREATE_QUESTIONNAIRE_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };

        default:
            return state;
    }
};
