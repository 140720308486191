import {
  SERVICE_HISTORY_ERROR,
  SERVICE_HISTORY_LOADING,
  SERVICE_HISTORY_SUCCESS,
} from '../types/serviceHistoryTypes'

export const initialState = {
  loading: false,
  error: false,
  serviceHistory: [],
}

const serviceHistoryReducer = (state: any = initialState, data: any) => {
  switch (data.type) {
    case SERVICE_HISTORY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case SERVICE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        serviceHistory: data.payload,
      }
    case SERVICE_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        serviceHistory: [],
        error: true,
      }
    default:
      return state
  }
}
export default serviceHistoryReducer
