import { FETCH_CONTACT_INFORMATION_ERROR, FETCH_CONTACT_INFORMATION_LOADING, FETCH_CONTACT_INFORMATION_SUCCESS } from "../types/contactInformationType"

const initialState = {
    loading: false,
    error: false,
    contactInformation: {}
}
const contactInformationReducer = (state:any = initialState ,payload:any) => {
    switch (payload.type) {
        case FETCH_CONTACT_INFORMATION_LOADING: 
        return {
            ...state,
            loading: true,
        }
        case FETCH_CONTACT_INFORMATION_SUCCESS:
            return {
                ...state,
                loading:false,
                error: false,
                contactInformation: payload.payload
            }
        case FETCH_CONTACT_INFORMATION_ERROR:
            return {
                    ...state,
                    loading: false,
                    error: true,
                    contactInformation:{}
                }
        default: 
        return state
    }
}
export default contactInformationReducer