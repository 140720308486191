import { fetchDataType } from '../types'

const initialState = {
  loading: false,
  error: false,
  value: {},
}
const fetchDataReducer = (state: any = initialState, data: any) => {
  switch (data.type) {
    case fetchDataType.LOADING:
      return {
        ...state,
        loading: true,
      }
    case fetchDataType.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        value: data.payload,
      }
    case fetchDataType.ERROR:
      return {
        ...state,
        loading: false,
        value: {},
        error: true,
      }
    default:
      return state
  }
}
export default fetchDataReducer
