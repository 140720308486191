export const CategoryTypes = {
    LOADING: 'CAT_LOADING',
    SUCCESS: 'CAT_SUCCESS'
};
export interface ICategoryInitailType {
    loading: boolean;
    categories: ICategoryType[];
}
export interface ICategoryType {
    _id: string;
    category: string;
    createAt: string;
    updateAt: string;
    totalBlog: Number;
    details: {
        lang: string;
        category: string;
        _id: string;
    }[];
    slug: string;
}
