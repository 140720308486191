import { ALL_LANGUAGES } from "../../constants"
import commonArabic from "./arabic";
import getCommonEnglish from "./english"
import getCommonFrench from "./french";
import commonKinyarwanda from "./kinyarnwanda";
import commonXhosa from "./xhosa";
import commonZulu from "./zulu";
import commonWolof from "./wolof";
import { getTopLevelDomain } from "../../utils/translation";

const topLevelDomain = getTopLevelDomain();
const auto24Domain = `AUTO24.${topLevelDomain}`;

const commonLang = {
  [ALL_LANGUAGES.english.value]: getCommonEnglish(auto24Domain),  
  [ALL_LANGUAGES.french.value]: getCommonFrench(auto24Domain), 
  [ALL_LANGUAGES.arabic.value]: commonArabic,  
  [ALL_LANGUAGES.zulu.value]: commonZulu, 
  [ALL_LANGUAGES.xhosa.value]: commonXhosa, 
  [ALL_LANGUAGES.kinyarnwanda.value]: commonKinyarwanda, 
  [ALL_LANGUAGES.wolof.value]: commonWolof, 
}

export default commonLang;