export const wishlistDataType = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

export const WISH_LIST_LOADING = 'WISH_LIST_LOADING'
export const WISH_LIST_SUCCESS = 'WISH_LIST_SUCCESS'
export const WISH_LIST_ERROR = 'WISH_LIST_ERROR'

export const ADD_WISH_LIST = 'ADD_WISH_LIST'

export const ADD_WISH_LIST_SUCCESS = 'ADD_WISH_LIST_SUCCESS'

export interface IWishListInitialType {
  loading: boolean
  error: boolean
  wishList: Array<IWishListType>
}
export interface IWishListType {
  _id: string
  user: any
  car: IWishListCarType
}
export interface IWishListCarType {
  _id: string
  name: string
  price: number
}
