import {
    FETCH_FEATURED_CARS
} from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { ICar } from '../../interface/car';

export interface IFeaturedCars {
    cars: ICar[];
    totalFeatured: number | null;
}

export interface IFeaturedCarsState {
    featuredCars: IFeaturedCars;
}

const initialState: IFeaturedCarsState = {
    featuredCars: {
        cars: [],
        totalFeatured: null
    }
};

const FetchFeaturedCarsReducer = (state = initialState, action: ActionType): IFeaturedCarsState => {
    switch (action.type) {
        case FETCH_FEATURED_CARS:
            return {
                ...state,
                featuredCars: {...state.featuredCars,...action.payload}
            };

        default:
            return state;
    }
};

export default FetchFeaturedCarsReducer;
