export const FETCH_FINANCE_PARTNER_LOADING = 'FETCH_FINANCE_PARTNER_LOADING';
export const FETCH_FINANCE_PARTNER_SUCCESS = 'FETCH_FINANCE_PARTNER_SUCCESS';
export const FETCH_FINANCE_PARTNER_ERROR = 'FETCH_FINACE_PARTNER_ERROR';

export interface IFinancePartnerInitialType {
    loading: boolean;
    error: boolean;
    financingPartners: Array<IFinancePartnerType>;
}

export interface IFinancePartnerType {
    _id: string;
    name: string;
    image: string;
    page: any;
}
