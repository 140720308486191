import { GET_STORES } from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { IStore } from '../../interface/store';

export interface IStoreState {
    stores: IStore[];
}

const initialState: IStoreState = {
    stores: []
};

const storeReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_STORES:
            return {
                ...state,
                stores: action.payload
            };

        default:
            return state;
    }
};

export default storeReducer;
