import {
    GET_CARS,
    GET_CAR_AVAIL_BODY_TYPES,
    GET_CAR_AVAIL_COLOR,
    GET_CAR_BODY_TYPES,
    GET_CAR_BRANDS,
    GET_CAR_COLORS
} from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import {
    ICarBodyType,
    ICarBrand,
    ICarColors,
    ICarResponse
} from '../../interface/car';

export interface ICarState {
    cars: ICarResponse;
    bodyTypes: ICarBodyType[];
    availableBodyTypes: ICarBodyType[];
    availableCarColor: ICarColors[];
    brands: ICarBrand[];
    carColors: ICarColors[];
}

const initialState: ICarState = {
    cars: {
        cars: [],
        total: 0
    },
    bodyTypes: [],
    availableBodyTypes: [],
    carColors: [],
    availableCarColor: [],
    brands: []
};

const carReducer = (state = initialState, action: ActionType): ICarState => {
    switch (action.type) {
        case GET_CARS:
            return {
                ...state,
                cars: action.payload
            };

        case GET_CAR_BODY_TYPES:
            return {
                ...state,
                bodyTypes: action.payload
            };
        case GET_CAR_AVAIL_BODY_TYPES:
            return {
                ...state,
                availableBodyTypes: action.payload
            };

        case GET_CAR_BRANDS:
            return {
                ...state,
                brands: action.payload
            };
        case GET_CAR_COLORS:
            return {
                ...state,
                carColors: action.payload
            };
        case GET_CAR_AVAIL_COLOR:
            return {
                ...state,
                availableCarColor: action.payload
            };
        default:
            return state;
    }
};

export default carReducer;
