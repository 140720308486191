import { ActionType } from '../../interface/actionType';
import { ITestimonial } from '../../interface/testimonial';
import {
    FETCH_TESTIMONIAL_ERROR,
    FETCH_TESTIMONIAL_LOADING,
    FETCH_TESTIMONIAL_SUCCESS
} from '../types/testimonialType';

export interface ITestimonialState {
    testimonials: ITestimonial[];
    loading: boolean;
    error: boolean;
}

const initState: ITestimonialState = {
    loading: false,
    testimonials: [],
    error: false
};

const testimonialReducer = (
    state = initState,
    action: ActionType
): ITestimonialState => {
    let { type, payload } = action;

    switch (type) {
        case FETCH_TESTIMONIAL_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case FETCH_TESTIMONIAL_SUCCESS:
            return {
                ...state,
                testimonials: payload,
                loading: false,
                error: false
            };
        case FETCH_TESTIMONIAL_ERROR:
            return {
                ...state,
                testimonials: [],
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default testimonialReducer;
// const testimonialReducer = (
//     state = initState,
//     action: ActionType
// ): ITestimonialState => {
//     let { type, payload } = action;

//     switch (type) {
//         case GET_TESTIMONIALS:
//             return {
//                 ...state,
//                 testimonials: payload
//             };

//         default:
//             return state;
//     }
// };

// export default testimonialReducer;
