import {
    FETCH_FINANCE_PARTNER_LOADING,
    FETCH_FINANCE_PARTNER_ERROR,
    FETCH_FINANCE_PARTNER_SUCCESS,
    IFinancePartnerInitialType
} from '../types/financePartnerTypes';

const initialState: IFinancePartnerInitialType = {
    loading: false,
    error: false,
    financingPartners: []
};

const financePartnerReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case FETCH_FINANCE_PARTNER_LOADING:
            return {
                ...state,
                loading: true
            };
        case FETCH_FINANCE_PARTNER_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };
        case FETCH_FINANCE_PARTNER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                financingPartners: data.payload
            };
        default:
            return state;
    }
};
export default financePartnerReducer;
