export const FETCH_BLOG_LOADING = 'FETCH_BLOG_LOADING';
export const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS';
export const FETCH_BLOG_ERROR = 'FETCH_BLOG_ERROR';
export const FETCH_ONE_BLOG = 'FETCH_ONE_BLOG';
export const FETCH_ALL_CARS_BRAND = 'FETCH_ALL_CARS_BRAND';

export interface IBlogInitailType {
    loading: boolean;
    error: boolean;
    blogs: IBlogDetailType[];
    featuredBlogs: IBlogDetailType[];
    latestBlogs: IBlogDetailType[];
    blog: IBlogDetailType | null;
    carBrands: {
        id: string;
        brand: string;
        image: string;
    } | null;
}

export interface IBlogDetailType {
    _id: string;
    title: string;
    details: { lang: string; title: string; description: string };
    image: string;
    type: string;
    cartype: string;
    authors: string;
    author: {
        _id: string;
        name: string;
        email: string;
    };
    category: {
        _id: string;
        category: string;
    };
    featured: boolean;
    createdAt: string;
    updatedAt: string;
    slug: string;
}
