import {
    FETCH_CHEAPEST_CAR_LIST,
    FETCH_ELECTRIC_CAR_LIST,
    FETCH_IMPORTED_CAR_LIST,
    FETCH_NEW_CAR_LIST
} from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { ICar } from '../../interface/car';

export interface ICarListType {
    cars: ICar[];
    total: number | null;
}

export interface ICarListTypeState {
    newCars: ICarListType;
    electricCars: ICarListType;
    importedCars: ICarListType;
    cheapestCars: ICarListType;
}

const initialState: ICarListTypeState = {
    newCars: {
        cars: [],
        total: null
    },
    electricCars: {
        cars: [],
        total: null
    },
    importedCars: {
        cars: [],
        total: null
    },
    cheapestCars: {
        cars: [],
        total: null
    }
};

const fetchNewCarListReducer = (state = initialState, action: ActionType): ICarListTypeState => {
    switch (action.type) {
        case FETCH_NEW_CAR_LIST:
            return {
                ...state,
                newCars: {...state.newCars,...action.payload}
            };

        case FETCH_ELECTRIC_CAR_LIST:
            return {
                ...state,
                electricCars: {...state.newCars,...action.payload}
            };

        case FETCH_IMPORTED_CAR_LIST:
            return {
                ...state,
                importedCars: {...state.newCars,...action.payload}
            };

        case FETCH_CHEAPEST_CAR_LIST:
            return {
                ...state,
                cheapestCars: {...state.cheapestCars,...action.payload}
            };

        default:
            return state;
    }
};

export default fetchNewCarListReducer;
