import Cookies from 'js-cookie';
import { sessionExpireDate } from '../constants';

class TokenService {
    getLocalRefreshToken() {
        return Cookies.get('refreshToken');
    }
    getLocalAccessToken() {
        return Cookies.get('accessToken');
    }
    updateLocalAccessToken(accessToken: string, refToken: string) {
        let r = Cookies.get('remember');
        let config = r
            ? {
                  expires: sessionExpireDate,
                  secure: true
              }
            : { secure: true };
        Cookies.set('accessToken', accessToken, config);
        Cookies.set('refreshToken', refToken, config);
    }
    setUser(user: Object) {
        localStorage.setItem('user', JSON.stringify(user));
    }
    getUser(): any | null {
        let user = localStorage.getItem('user');
        return user ? JSON.parse(user) : null;
    }
    removeLocalUser() {
        Cookies.remove('remember');
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        localStorage.removeItem('user');
    }
}
export default new TokenService();
