const heroXhosa = {
    searchBarTitle: "Indlela ekhawulezayo nelula yokuthenga okanye ukuthengisa iimoto",
    searchBarPlaceholder: "Funa imoto oyifunayo",
    willingToSellYourUsedCar: "Ufuna ukuthengisela imoto yakho?",
    searchByCategory: "Khangela ngokodidi",
    whyChooseAuto: "Why Choose AUTO24?",
    searchCarByTheirBodyType: "Khangela imoto ngohlobo lomzimba wabo",
    searhcarbybrands: "Khangela imoto ngeempawu",
    exploreCar: "Ukuhlola iimoto",
    chooseCar: "Khetha imoto",
    buyACar: "Thenga imoto yakho",
    buyACarDesc: "Purchase it and relax, we'll take care of the rest!",
    buyACarDescs: "Buy safely and relax, we'll take care of everything!",
    weAreAroundTheArea: "Sise Rhawutini",
    youcanfindusaroundthislocation: "Ungasifumana kule ndawo",
    SearchByLocation: "Khangela ngendawo",
    questionSubtitle: "Nantsi imibuzo eyahlukileyo evela kubantu abohlukeneyo onokuthi wazi ngayo ngathi",
    askQuestions: "Buza imibuzo",
    questions: "Imibuzo",
    carsellInfo: "Sinike iinkcukacha malunga nesithuthu sakho",
    yourCar: "Imoto yakho",
    valuation: "Ukuxabisa",
    valuationInfo: "Siza kuhlola imoto yakho kwindawo yakho okanye uze kuthi",
    sellCar: "Thengisa",
    sellCarInfo: "Fumana imali yakho ngaphantsi kweeyure ezingama-24",
    happycustomerHappyus: "Abathengi abonwabileyo,sonwabe thina",
    questionTextAreaPlaceHolder: "Ngowuphi umbuzo ofuna ukuwubuza?",
    discovercar: "Fumana iimoto zethu",
    discovercarSubtitle: "Khangela uluhlu lwethu lweemoto eziqinisekisiweyo ezisetyenzisiweyo",
    chooseACarSubtitle: "Khetha imoto ekufanele kakhulu",
    chooseACarSubtitles: "Khetha imoto oyithandayo kwaye oyifunayo"
}

export default heroXhosa;
