export const GET_OUR_TEAM_BEGIN= 'GET_OUR_TEAM_BEGIN';
export const GET_OUR_TEAM_SUCCESS= 'GET_OUR_TEAM_SUCCESS';
export const GET_OUR_TEAM_FAILED= 'GET_OUR_TEAM_FAILED';

export interface IOurTeamInitialTypes {
    ourTeam: Array<IOurTeamTypes>;
    total: number;
    loading: boolean;
    currentPage: number | null;
    pageLimit: number | null;
    error: boolean | null;
}

export interface IOurTeamTypes {
    _id: string;
    name: string;
    position: string;
    address: string;
    email: string;
    mobileCode: string;
    mobileNumber: string;
    image: string;
    telephoneCode?: string | null;
    telephoneNumber?: string | null;
    whatsappCode?: string | null;
    whatsappNumber?: string | null;
}
