import { useEffect } from 'react';
import { getMessagePlusConfig } from '../../utils/translation';

const MessagePlus = () => {
  
  useEffect(() => {
    const { brand, chatWidget } = getMessagePlusConfig()

    if(brand?.phoneNumber && brand?.qrUrl) {
      const url = 'https://cdn.jsdelivr.net/gh/messageplusio/public@1d672144215a2e897e6521ded64dc0bde44625c9/wawidget.js';
      const script = document.createElement('script');
      
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
  
      script.onload = () => {
        const options = {
          enabled: true,
          chatButtonSetting: {
            backgroundColor: "#00e785",
            ctaText: chatWidget?.ctaText,
            borderRadius: "25",
            marginLeft: "0",
            marginRight: "20",
            marginBottom: "20",
            ctaIconMP: false,
            position: "right",
            desktopText: chatWidget?.desktopText,
            mobileText: chatWidget?.mobileText,
          },
          brandSetting: {
            brandName: "Message Plus",
            brandSubTitle: "undefined",
            brandImg: "https://avatars.githubusercontent.com/u/123689772?s=200&v=4",
            welcomeText: "",
            messageText: brand?.messageText,
            backgroundColor: "#00e785",
            ctaText: brand?.ctaText,
            borderRadius: "25",
            autoShow: false,
            phoneNumber: brand?.phoneNumber,
            qrUrl: brand?.qrUrl,
            showButton: true,
          },
        };
        
        window.CreateWhatsappChatWidget(options); // Ensure this function is available in the global scope
      };
  
      document.body.appendChild(script);
      
      // Cleanup function to remove the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);

  return null;
};

export default MessagePlus;
