export const FETCH_TESTIMONIAL_LOADING = 'FETCH_TESTIMONIAL_LOADING';
export const FETCH_TESTIMONIAL_ERROR = 'FETCH_TESTIMONIAL_ERROR';
export const FETCH_TESTIMONIAL_SUCCESS = 'FETCH_TESTIMONIAL_SUCCESS';

export interface ITestimonialInitialTypes {
    loading: boolean;
    error: boolean;
    testimonials: Array<ITestimonialsTypes>;
}

export interface ITestimonialsTypes {
    _id: string;
    fullName: string;
    company: string;
    position: string;
    message: string;
    profile: string;
}
