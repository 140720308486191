import { $FIXME } from '../../constants';

export interface IUserInitialType {
    _id: string;
    name: string;
    email: string;
    profile: string | null;
    emailVerified: boolean;
    phoneNumberVerified: boolean;
    gender: string;
    dob: string;
    bio: string;
    role: {
        name: string;
    };
    slug: string;
    phoneNumber: {
        code: string;
        number: $FIXME;
    };
    social: {
        facebook?: string;
        instagram?: string;
        twitter?: string;
    };
    address: {
        country: string;
        province: string;
        district: string;
        localAddress: string;
    };
}

export const AuthTypes = {
    LOG_IN: 'LOGIN',
    LOG_OUT: 'LOGOUT',
    SHOW_LOGIN_MODAL: 'SHOW_LOGIN_MODAL',
    RESET_LOGIN_MODAL: 'RESET_LOGIN_MODAL',
    UPDATE_USER: 'UPDATE_USER'
    // DELETE_ACCOUNT: 'DELETE_ACCOUNT'
};
