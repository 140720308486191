import { GET_CONTACTINFO } from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { IContactInformation } from '../../interface/contact';

export interface IContactState {
    contact: IContactInformation;
}

const initialState: IContactState = {
    contact: {}
};

const contactReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_CONTACTINFO:
            return {
                ...state,
                contact: action.payload
            };

        default:
            return state;
    }
};

export default contactReducer;
