import { ICar } from '../../interface/car';

export const CAR_LIST_LOADING = 'CAR_LIST_LOADING';
export const CAR_LIST_ERROR = 'CAR_LIST_ERROR';
export const CAR_LIST_SUCCESS = 'CAR_LIST_SUCCESS';

export const GET_CARS_DETAILS = 'GET_CARS_DETAILS';

export const CAR_BRANDS_LOADING = 'CARS_BRANDS_LOADING';
export const CAR_BRANDS_ERROR = 'CAR_BRANDS_ERROR';
export const CAR_BRANDS_SUCCESS = 'CAR_BRANDS_SUCCESS';

// For home page
export const ADD_RECENT_CARS_LIST = 'ADD_RECENT_CARS_LIST';
export const RECENT_CARS_LIST_PAGE = 'RECENT_CARS_LIST_PAGE';
export const RESET_RECENT_CARS_LIST = 'RESET_RECENT_CARS_LIST';
export const ADD_NEW_CARS_LIST = 'ADD_NEW_CARS_LIST';
export const NEW_CARS_LIST_PAGE = 'NEW_CARS_LIST_PAGE';
export const RESET_NEW_CARS_LIST = 'RESET_NEW_CARS_LIST';

// For listing page
export const ADD_BUY_CARS_LIST = 'ADD_BUY_CARS_LIST';
export const RESET_BUY_CARS_LIST = 'RESET_BUY_CARS_LIST';
export const BUY_CARS_LIST_LOADING_BEGIN = 'BUY_CARS_LIST_LOADING_BEGIN';
export const BUY_CARS_LIST_LOADING_END = 'BUY_CARS_LIST_LOADING_END';
export const BUY_CARS_LIST_PAGE = 'BUY_CARS_LIST_PAGE';

export interface IInitialCarType {
    loading: boolean;
    error: string | null;
    cars: Array<ICar>;
    car: ICar;
    similarCars: Array<ICar>;
    totalSimilarCars: number;
    filtersUsed: string[];
    totalCar?: number;
}

// For Listing cars for infinite scrolling
export interface IInitialCarListType {
    // For home page
    // New cars
    newCars: Array<ICar>;
    newPage: number;
    totalNewCars?: number;
    // Recently added cars
    recentCars: Array<ICar>;
    recentPage: number;
    totalRecentCars?: number;

    // For listing page
    buyCars: Array<ICar>;
    totalBuyCars?: number;
    buyIsLoading: boolean;
    buyPage: number;
    totalBuyPage: number;
}
