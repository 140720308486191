import {
    FETCH_CARS_MODELS_ERROR,
    FETCH_CAR_AVAILABLE_MODEL,
    FETCH_CAR_MODELS_LOADING,
    FETCH_CAR_MODELS_SUCCESS
} from '../types/carModelsTypes';

const initialState = {
    loading: false,
    error: false,
    carModels: [],
    carAvilableModel: []
};

const carModelsReducer = (state: any = initialState, dispatch: any) => {
    switch (dispatch.type) {
        case FETCH_CAR_MODELS_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
                carModels: []
            };
        case FETCH_CAR_MODELS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                carModels: dispatch.payload
            };
        case FETCH_CAR_AVAILABLE_MODEL:
            return {
                ...state,
                loading: false,
                error: false,
                carAvilableModel: dispatch.payload
            };
        case FETCH_CARS_MODELS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                carModels: []
            };

        default:
            return state;
    }
};
export default carModelsReducer;
