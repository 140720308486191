export const subscribeDataType = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
};

export interface ISubscribeDataType {
    loading: boolean;
    error: boolean;
    subscribers: Array<any>;
    email: string;
}

export const SUBSCRIBE_LOADING = 'SUBSCRIBE_LOADING';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'SUBSCRIBE_ERROR';
