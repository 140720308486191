import {
    ADD_WISH_LIST_SUCCESS,
    WISH_LIST_ERROR,
    WISH_LIST_LOADING,
    WISH_LIST_SUCCESS
} from '../types/wishListTypes';

const initialState = {
    loading: false,
    error: false,
    wishList: []
};
const wishListReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case WISH_LIST_LOADING:
            return {
                ...state,
                loading: true
            };
        case WISH_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                wishList: data.payload
            };
        case WISH_LIST_ERROR:
            return {
                ...state,
                loading: false,
                wishList: [],
                error: true
            };
        case ADD_WISH_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                wishlist: [
                    ...state.wishlist,
                    {
                        _id: data.payload,
                        car: {
                            _id: data.payload,
                            name: ''
                        }
                    }
                ]
            };
        default:
            return state;
    }
};
export default wishListReducer;
