import { COUNTRY_TOP_LEVEL_DOMAIN } from "../../constants";

const heroEnglish = (topLevelDomain: string) => {
    switch(topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.bj:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are Around The Area ABIDJAN',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are Around The Area ABIDJAN',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "يع سيارة",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
        case COUNTRY_TOP_LEVEL_DOMAIN.coke:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are Around The Area ABIDJAN',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We are in Johannesburg',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are Around The Area CASABLANCA',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are in Kigali',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are Around The Area Dakar',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
        case COUNTRY_TOP_LEVEL_DOMAIN.tg:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are Around The Area ABIDJAN',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }
            case COUNTRY_TOP_LEVEL_DOMAIN.tz:
        return {
            searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
            searchBarPlaceholder: "Search your desired car",
            willingToSellYourUsedCar: "Looking to sell your car?",
            searchByCategory: "Search By Category",
            whyChooseAuto: "Why Choose AUTO24?",
            searchCarByTheirBodyType: "Search car by their body type",
            searhcarbybrands: "Search car by brands",
            exploreCar: "Explore Cars",
            chooseCar: "Choose a Car",
            buyACar: "Buy your car",
            buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
            buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
            weAreAroundTheArea: 'We Are Around The Area ABIDJAN',
            youcanfindusaroundthislocation: "You can find us around this location",
            SearchByLocation: "Search By Location",
            questionSubtitle: "Here are different question from different people by which you may know about us",
            askQuestions: "Ask Questions",
            questions: "Questions",
            carsellInfo: "Give us details about your vehicle",
            yourCar: "Your Car",
            valuation: "Valuation",
            valuationInfo: "We come to inspect your car at your place or you come to us",
            sellCar: "Sell",
            sellCarInfo: "Receive your money in less than 24 hours!",
            happycustomerHappyus: "Happy customers, Happy us",
            questionTextAreaPlaceHolder: "What question do you want to ask?",
            discovercar: "Discover our cars",
            discovercarSubtitle: "Browse our list of certified used cars",
            chooseACarSubtitle: "Select the car that suits you most",
            chooseACarSubtitles: "Select the car you like and want"
        }
        case COUNTRY_TOP_LEVEL_DOMAIN.ug:
        return {
            searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
            searchBarPlaceholder: "Search your desired car",
            willingToSellYourUsedCar: "Looking to sell your car?",
            searchByCategory: "Search By Category",
            whyChooseAuto: "Why Choose AUTO24?",
            searchCarByTheirBodyType: "Search car by their body type",
            searhcarbybrands: "Search car by brands",
            exploreCar: "Explore Cars",
            chooseCar: "Choose a Car",
            buyACar: "Buy your car",
            buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
            buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
            weAreAroundTheArea: 'We Are Around The Area ABIDJAN',
            youcanfindusaroundthislocation: "You can find us around this location",
            SearchByLocation: "Search By Location",
            questionSubtitle: "Here are different question from different people by which you may know about us",
            askQuestions: "Ask Questions",
            questions: "Questions",
            carsellInfo: "Give us details about your vehicle",
            yourCar: "Your Car",
            valuation: "Valuation",
            valuationInfo: "We come to inspect your car at your place or you come to us",
            sellCar: "Sell",
            sellCarInfo: "Receive your money in less than 24 hours!",
            happycustomerHappyus: "Happy customers, Happy us",
            questionTextAreaPlaceHolder: "What question do you want to ask?",
            discovercar: "Discover our cars",
            discovercarSubtitle: "Browse our list of certified used cars",
            chooseACarSubtitle: "Select the car that suits you most",
            chooseACarSubtitles: "Select the car you like and want"
        }
        default:
            return {
                searchBarTitle: "Fast And Easy Way to Buy Or Sell Cars",
                searchBarPlaceholder: "Search your desired car",
                willingToSellYourUsedCar: "Looking to sell your car?",
                searchByCategory: "Search By Category",
                whyChooseAuto: "Why Choose AUTO24?",
                searchCarByTheirBodyType: "Search car by their body type",
                searhcarbybrands: "Search car by brands",
                exploreCar: "Explore Cars",
                chooseCar: "Choose a Car",
                buyACar: "Buy your car",
                buyACarDesc: "Purchase it and relax, we’ll take care of the rest!",
                buyACarDescs: "Buy safely and relax, we‘ll take care of everything!",
                weAreAroundTheArea: 'We Are Around The Area Dakar',
                youcanfindusaroundthislocation: "You can find us around this location",
                SearchByLocation: "Search By Location",
                questionSubtitle: "Here are different question from different people by which you may know about us",
                askQuestions: "Ask Questions",
                questions: "Questions",
                carsellInfo: "Give us details about your vehicle",
                yourCar: "Your Car",
                valuation: "Valuation",
                valuationInfo: "We come to inspect your car at your place or you come to us",
                sellCar: "Sell",
                sellCarInfo: "Receive your money in less than 24 hours!",
                happycustomerHappyus: "Happy customers, Happy us",
                questionTextAreaPlaceHolder: "What question do you want to ask?",
                discovercar: "Discover our cars",
                discovercarSubtitle: "Browse our list of certified used cars",
                chooseACarSubtitle: "Select the car that suits you most",
                chooseACarSubtitles: "Select the car you like and want"
            }

    }
}

export default heroEnglish;
