import { CSSProperties } from 'react';

interface IProps {
    style?: CSSProperties;
    id?: string;
}

const SuspenseLoading = ({ style, id }: IProps) => {
    return (
        <div className="splash-screen">
            <div className="image-container">
                <img src="/logos/logo-text-blue.svg" alt="auto24" />
            </div>
        </div>
    );
};

export default SuspenseLoading;
