import { CategoryTypes, ICategoryInitailType } from "../types/categoryTypes";


const initialState:ICategoryInitailType={
    loading:false,
    categories:[]
}

export const categoryReducer=(state:ICategoryInitailType=initialState,action:any)=>{
    switch(action.type){
        case CategoryTypes.SUCCESS:
            return {
                categories:action.data,
                loading:false
            };
            default :
            return state;
    }

}
