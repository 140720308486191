import {
    GET_HOME_BLOGS,
    GET_HOME_RECENTLY_ADDED
} from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { IBlog } from '../../interface/blog';
import { ICarResponse } from '../../interface/car';

export interface IHomeState {
    recentlyAdded: ICarResponse;
    blogs: IBlog[];
}

const initialState: IHomeState = {
    recentlyAdded: {
        cars: [],
        total: 0,
        totalAvailableCars: 0
    },
    blogs: []
};

const homeReducer = (state = initialState, action: ActionType): IHomeState => {
    switch (action.type) {
        case GET_HOME_RECENTLY_ADDED:
            return {
                ...state,
                recentlyAdded: action.payload
            };

        case GET_HOME_BLOGS:
            return {
                ...state,
                blogs: action.payload
            };

        default:
            return state;
    }
};

export default homeReducer;
