export const QUESTIONNAIRE_LOADING = 'QUESTIONNAIRE_LOADING';
export const QUESTIONNAIRE_SUCCESS = 'QUESTIONNAIRE_SUCCESS';
export const QUESTIONNAIRE_ERROR = 'QUESTIONNAIRE_ERROR';

export const CREATE_QUESTIONNAIRE_LOADING = 'CREATE_QUESTIONNAIRE_LOADING';
export const CREATE_QUESTIONNAIRE_ERROR = 'CREATE_QUESTIONNAIRE_ERROR';
export const CREATE_QUESTIONNAIRE_SUCCESS = 'CREATE_QUESTIONNAIRE_SUCCESS';

export const FETCH_ALL_QUESTIONNAIRE_LOADING =
    'FETCH_ALL_QUESTIONNAIRE_LOADING';
export const FETCH_ALL_QUESTIONNAIRE_SUCCESS =
    'FETCH_ALL_QUESTIONNAIRE_SUCCESS';
export const FETCH_ALL_QUESTIONNAIRE_ERROR = 'FETCH_ALL_QUESTIONNAIRE_ERROR';

export interface IQuestionnaireInitialType {
    loading: boolean;
    error: boolean;
    faqs: Array<IFaqType>;
    allFaqs: Array<IFaqType>;
}

export interface IFaqType {
    _id: string;
    phoneNumber: string;
    question: string;
    answer: string;
    details: {
        answer: string;
        lang: string;
        question: string;
        _id: string;
    }[]
}
