import axios from 'axios';
import { $FIXME, BASE_URL } from '../constants';
import TokenService from './TokenService';

export const setLanguageHeader = (langCode: string) => {
    api.defaults.headers.common['Accept-Language'] = langCode;
};
export const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
export const protectedAPI = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
protectedAPI.interceptors.request.use(
    (config: $FIXME) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers['authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
protectedAPI.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== '/auth/login' && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await axios.get(
                        BASE_URL + '/auth/refresh-token',
                        {
                            headers: {
                                Authorization: `Bearer ${
                                    TokenService.getLocalRefreshToken() as string
                                }`
                            }
                        }
                    );
                    const { authToken, refreshToken } = rs.data;

                    TokenService.updateLocalAccessToken(
                        authToken,
                        refreshToken
                    );
                    return protectedAPI(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);
