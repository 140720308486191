import {
    ADD_BUY_CARS_LIST,
    IInitialCarListType,
    RESET_BUY_CARS_LIST,
    BUY_CARS_LIST_LOADING_BEGIN,
    BUY_CARS_LIST_LOADING_END,
    BUY_CARS_LIST_PAGE,
    ADD_RECENT_CARS_LIST,
    RECENT_CARS_LIST_PAGE,
    RESET_RECENT_CARS_LIST,
    ADD_NEW_CARS_LIST,
    NEW_CARS_LIST_PAGE,
    RESET_NEW_CARS_LIST,
} from '../types/carListTypes';

const initialState: IInitialCarListType = {
    // For home page infinite scrolling cars storage
    // New cars
    newCars: [],
    newPage: 1,
    totalNewCars: 0,
    // Recently added cars
    recentCars: [],
    recentPage: 1,
    totalRecentCars: 0,

    // For listing page infinite scrolling cars storage
    buyCars: [],
    totalBuyCars: 0,
    totalBuyPage: 0,
    buyIsLoading: false,
    buyPage: 1,
};
const carListReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        // For home page
        case ADD_RECENT_CARS_LIST:
            return {
                ...state,
                recentCars: Array.from(new Set([...state.recentCars, ...data.payload?.cars])),
                totalRecentCars: data.payload?.totalFeatured
            };
        case RECENT_CARS_LIST_PAGE:
            return {
                ...state,
                recentPage: data.payload
            };

        case RESET_RECENT_CARS_LIST:
            return {
                ...state,
                recentCars: [],
                recentPage: 1,
                totalRecentCars: 0,
            };

        case ADD_NEW_CARS_LIST:
            return {
                ...state,
                newCars: Array.from(new Set([...state.newCars, ...data.payload?.cars])),
                totalNewCars: data.payload?.total
            };
        case NEW_CARS_LIST_PAGE:
            return {
                ...state,
                newPage: data.payload
            };

        case RESET_NEW_CARS_LIST:
            return {
                ...state,
                newCars: [],
                newPage: 1,
                totalNewCars: 0,
            };

        // For listing page
        case BUY_CARS_LIST_LOADING_BEGIN:
            return {
                ...state,
                buyIsLoading: true
            };
        case BUY_CARS_LIST_LOADING_END:
            return {
                ...state,
                buyIsLoading: false
            };
        case ADD_BUY_CARS_LIST:
            return {
                ...state,
                buyCars: Array.from(new Set([...state.buyCars, ...data.payload?.cars])),
                totalBuyCars: data.payload?.total,
                totalBuyPage: data.payload?.totalPages
            };
        case BUY_CARS_LIST_PAGE:
            return {
                ...state,
                buyPage: data.payload
            };

        case RESET_BUY_CARS_LIST:
            return {
                ...state,
                buyCars: [],
                totalBuyCars: 0,
                totalBuyPage: 0,
                buyIsLoading: false,
                buyPage: 1,
            };
        default:
            return state;
    }
};
export default carListReducer;
