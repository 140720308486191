import { GET_CUSTOMER_GALLERY } from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { ICustomerGallery } from '../../interface/customerGallery';

export interface ICustomerGalleryState {
    gallery: ICustomerGallery[];
}

const initState: ICustomerGalleryState = {
    gallery: []
};

const customerGalleryReducer = (
    state = initState,
    action: ActionType
): ICustomerGalleryState => {
    let { type, payload } = action;

    switch (type) {
        case GET_CUSTOMER_GALLERY:
            return {
                ...state,
                gallery: payload
            };

        default:
            return state;
    }
};

export default customerGalleryReducer;
