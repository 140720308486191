const metaArabic = {
    homePageTitle: "AUTO24.ma - سيارات مستعملة معتمدة في المغرب | شراء وبيع السيارات في جميع أنحاء البلاد",
    homePageDesc: "الوصف: سوقك المحلي في المغرب لشراء وبيع السيارات المستعملة المعتمدة. استمتع بتقييمات مجانية، مبيعات سريعة، وضمان الجودة في جميع أنحاء الوطن.",
    homePageKeywords: "الكلمات الدليلية: سيارات مستعملة معتمدة، شراء سيارات المغرب، بيع سيارات المغرب، سوق السيارات، AUTO24 المغرب، سيارات ذات جودة، مبيعات السيارات المغرب",
    homePageTitleOg: "AUTO24.ma - سيارات مستعملة معتمدة في المغرب | شراء وبيع السيارات في جميع أنحاء البلاد",
    homePageDescOg: "الوصف: سوقك المحلي في المغرب لشراء وبيع السيارات المستعملة المعتمدة. استمتع بتقييمات مجانية، مبيعات سريعة، وضمان الجودة في جميع أنحاء الوطن.",
    homePageKeywordsOg: "الكلمات الدليلية: سيارات مستعملة معتمدة، شراء سيارات المغرب، بيع سيارات المغرب، سوق السيارات، AUTO24 المغرب، سيارات ذات جودة، مبيعات السيارات المغرب",
    buyPageTitle: "العنوان: شراء سيارات مستعملة ذات جودة معتمدة في المغرب | AUTO24.ma",
    buyPageDesc: "الوصف: استكشاف مجموعة واسعة من السيارات المستعملة المعتمدة في AUTO24.ma . نحن نبسط بحثك عن السيارة المثالية التي تناسب ميزانيتك وأسلوب حياتك في جميع أنحاء المغرب.",
    buyPageKeywords: "الكلمات الدليلية: شراء السيارات المغرب، سيارات مستعملة معتمدة، AUTO24 المغرب، تسوق السيارات، سيارات معقولة السعر المغرب",
    buyPageTitleOg: "العنوان: شراء سيارات مستعملة ذات جودة معتمدة في المغرب | AUTO24.ma",
    buyPageDescOg: "الوصف: استكشاف مجموعة واسعة من السيارات المستعملة المعتمدة في AUTO24.ma . نحن نبسط بحثك عن السيارة المثالية التي تناسب ميزانيتك وأسلوب حياتك في جميع أنحاء المغرب.",
    buyPageKeywordsOg: "الكلمات الدليلية: شراء السيارات المغرب، سيارات مستعملة معتمدة، AUTO24 المغرب، تسوق السيارات، سيارات معقولة السعر المغرب",
    sellPageTitle: "العنوان: بيع سيارتك بسرعة وسهولة في المغرب | AUTO24.ma",
    sellPageDesc: "الوصف: ترغب في بيع سيارتك؟ AUTO24.ma يقدم طريقة بسيطة للقيام بذلك، مع توفير تقييمات مجانية ومبيعات سريعة في جميع أنحاء المغرب.",
    sellPageKeywords: "الكلمات الدليلية: بيع السيارات المغرب، مبيعات السيارات المستعملة المغرب، مبيعات السيارات السريعة، AUTO24 بيع السيارات، تقييم السيارات المغرب",
    sellPageTitleOg: "العنوان: بيع سيارتك بسرعة وسهولة في المغرب | AUTO24.ma",
    sellPageDescOg: "الوصف: ترغب في بيع سيارتك؟ AUTO24.ma يقدم طريقة بسيطة للقيام بذلك، مع توفير تقييمات مجانية ومبيعات سريعة في جميع أنحاء المغرب.",
    sellPageKeywordsOg: "الكلمات الدليلية: بيع السيارات المغرب، مبيعات السيارات المستعملة المغرب، مبيعات السيارات السريعة، AUTO24 بيع السيارات، تقييم السيارات المغرب",
    aboutPageTitle: "العنوان: عن AUTO24.ma - سوق السيارات المستعملة المعتمدة الموثوق في المغرب",
    aboutPageDesc: "الوصف: تعرف على المزيد عن AUTO24.ma ، السوق الرائد للسيارات المستعملة المعتمدة في المغرب. نقدم سيارات ذات جودة وتجربة شراء وبيع سلسة في جميع أنحاء البلاد.",
    aboutPageKeywords: "الكلمات الدليلية: عن AUTO24 المغرب، سوق السيارات المستعملة المعتمدة، مبيعات السيارات الموثوقة، تجار السيارات المغرب",
    aboutPageTitleOg: "العنوان: عن AUTO24.ma - سوق السيارات المستعملة المعتمدة الموثوق في المغرب",
    aboutPageDescOg: "الوصف: تعرف على المزيد عن AUTO24.ma ، السوق الرائد للسيارات المستعملة المعتمدة في المغرب. نقدم سيارات ذات جودة وتجربة شراء وبيع سلسة في جميع أنحاء البلاد.",
    aboutPageKeywordsOg: "الكلمات الدليلية: عن AUTO24 المغرب، سوق السيارات المستعملة المعتمدة، مبيعات السيارات الموثوقة، تجار السيارات المغرب",
    financePageTitle: "العنوان: تمويل سيارتك المستعملة بسهولة مع AUTO24.ma في المغرب",
    financePageDesc: "الوصف: اكتشاف خيارات التمويل المرنة لسيارتك المستعملة مع AUTO24.ma . نبسط عملية شراء السيارات في جميع أنحاء المغرب.",
    financePageKeywords: "الكلمات الدليلية: تمويل السيارات، الائتمان السيارات، AUTO24 المغرب، خيارات التمويل، قرض السيارات",
    financePageTitleOg: "العنوان: تمويل سيارتك المستعملة بسهولة مع AUTO24.ma في المغرب",
    financePageDescOg: "الوصف: اكتشاف خيارات التمويل المرنة لسيارتك المستعملة مع AUTO24.ma . نبسط عملية شراء السيارات في جميع أنحاء المغرب.",
    financePageKeywordsOg: "الكلمات الدليلية: تمويل السيارات، الائتمان السيارات، AUTO24 المغرب، خيارات التمويل، قرض السيارات",
    insurePageTitle: "العنوان: تأمين سيارتك بشكل موثوق مع AUTO24.ma في المغرب",
    insurePageDesc: "الوصف: الحصول على تأمين سيارة معقول وموثوق لسيارتك المستعملة مع AUTO24.ma . اختر من بين مجموعة من الخيارات لتلبية احتياجاتك في جميع أنحاء البلاد.",
    insurePageKeywords: "الكلمات الدليلية: التأمين السيارات، تغطية التأمين، AUTO24 المغرب، تأمين السيارات المستعملة، خيارات التأمين",
    insurePageTitleOg: "العنوان: تأمين سيارتك بشكل موثوق مع AUTO24.ma في المغرب",
    insurePageDescOg: "الوصف: الحصول على تأمين سيارة معقول وموثوق لسيارتك المستعملة مع AUTO24.ma . اختر من بين مجموعة من الخيارات لتلبية احتياجاتك في جميع أنحاء البلاد.",
    insurePageKeywordsOg: "الكلمات الدليلية: التأمين السيارات، تغطية التأمين، AUTO24 المغرب، تأمين السيارات المستعملة، خيارات التأمين",
    blogPageTitle: "العنوان: مدونة AUTO24.ma - نصائح، حيل، وأخبار الصناعة في المغرب",
    blogPageDesc: "الوصف: تبقى على اطلاع بأحدث الأخبار، النصائح، والحيل في سوق السيارات المغربي. AUTO24.ma هو مصدرك الأول لكل شيء يتعلق بالسيارات المستعملة.",
    blogPageKeywords: "الكلمات الدليلية: مدونة AUTO24، أخبار السيارات، نصائح السيارات، سوق السيارات المغرب، نصائح السيارات",
    blogPageTitleOg: "العنوان: مدونة AUTO24.ma - نصائح، حيل، وأخبار الصناعة في المغرب",
    blogPageDescOg: "الوصف: تبقى على اطلاع بأحدث الأخبار، النصائح، والحيل في سوق السيارات المغربي. AUTO24.ma هو مصدرك الأول لكل شيء يتعلق بالسيارات المستعملة.",
    blogPageKeywordsOg: "الكلمات الدليلية: مدونة AUTO24، أخبار السيارات، نصائح السيارات، سوق السيارات المغرب، نصائح السيارات",
    qNaPageTitle: "AUTO24 - الأسئلة المتداولة",
    contactUsPageTitle: "AUTO24 - اتصل بنا",
    privacyPolicyPageTitle: "AUTO24 - سياسة الخصوصية",
    termsNusePageTitle: "AUTO24 - الشروط والأحكام",
    carDetailPageTitle: "شراء {{جعل}},{{السعر}} DH - AUTO24",
    carDetailPageDesc: "اشتر سيارة مستعملة معتمدة من {{make}} من {{PRIX}} DH مع AUTO24 ، أبيدجان ، ساحل العاج",
    buyPageDescWhenBrand: "أفضل الأسعار والخدمات لسيارات مستعملة {{BRAND}} في أبيدجان، ساحل العاج. سياسة الاسترداد والضمان والتأمين والصيانة وكل ما هو مشمول!"
}

export default metaArabic;