import {
  CREATE_CONTACT_US_ERROR,
  CREATE_CONTACT_US_LOADING,
  CREATE_CONTACT_US_SUCCESS,
} from '../types/contactUsTypes'

const initialState = {
  loading: false,
  error: false,
  contactQuery: {},
}

const contactUsReducer = (state: any = initialState, data: any) => {
  switch (data.type) {
    case CREATE_CONTACT_US_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        contactQuery: {},
      }
    case CREATE_CONTACT_US_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        contactQuery: {},
      }
    case CREATE_CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    default:
      return state
  }
}
export default contactUsReducer
