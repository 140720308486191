export const FETCH_CAR_BRANDS_LOADING = 'FETCH_CAR_BRANDS_LOADING';
export const FETCH_CAR_BRANDS_SUCCESS = 'FETCH_CAR_BRANDS_SUCCESS';
export const FETCH_CAR_BRANDS_ERROR = 'FETCH_CAR_BRANDS_ERROR';
export const FETCH_CAR_AVAILABLE_BRAND = 'FETCH_CAR_AVAILABLE_BRAND';

export interface ICarBrandsInitialType {
    loading: boolean;
    error: boolean;
    carBrands: Array<ICarBrandsType>;
    carAvailableBrands: Array<ICarBrandsType>;
}

export interface ICarBrandsType {
    _id: string;
    brand: string;
    image: string;
    details?: {
        _id: string;
        brand: string;
    }[];
    slug: string;
}
