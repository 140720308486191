export const FETCH_FINANCING_LOADING = 'FETCH_FINANCING_LOADING';
export const FETCH_FINANCING_SUCCESS = 'FETCH_FINANCING_SUCCESS';
export const FETCH_FINANCING_ERROR = 'FETCH_FINACING_ERROR';

export interface IFinancingInitialType {
    loading: boolean;
    error: boolean;
    financing: Array<IFinancingType>;
}

export interface IFinancingType {
    _id: string;
    companyName: string;
    image: string;
    monthlyEMI: string;
    rateOfInterest: number;
    tenure: number;
}
