import {
    CAR_VALUATION_ERROR,
    CAR_VALUATION_LOADING,
    CAR_VALUATION_SUCCESS,
    ICarValuationInitialType
} from '../types/carValuationTypes';

const initialState: ICarValuationInitialType = {
    loading: false,
    error: false,
    data: null,
};

const carValuationReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case CAR_VALUATION_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case CAR_VALUATION_ERROR:
            return {
                ...state,
                loading: false,
                data: null,
                error: true
            };
        case CAR_VALUATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                data: data.payload
            };

        default:
            return state;
    }
};
export default carValuationReducer;
