import { IBrand, IModel } from '../../interface/car';

export const CREATE_SELL_CAR_LOADING = 'CREATE_SELL_CAR_LOADING';
export const CREATE_SELL_CAR_SUCCESS = 'CREATE_SELL_CAR_SUCCESS';
export const CREATE_SELL_CAR_ERROR = 'CREATE_SELL_CAR_ERROR';

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';

export interface ISellingCarsInitialType {
    loadingSellData: boolean;
    error: boolean;
    sellingCars: Array<ISellingCarsType>;
    formData: any;
}
export interface ISellingCarsType {
    brand: IBrand;
    model: IModel;
    fuelType: string;
    transmission: string;
    condition: string;
    mileage: number;
    yearOfManufacture: string;
    fullName: string;
    phoneNumber: string;
    isVerified: boolean;
    _id: string;
}
