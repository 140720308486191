import { message } from 'antd';
import { Dispatch } from 'redux';
import { $FIXME, getWishList } from '../../constants';
import {
    WISH_LIST_ERROR,
    WISH_LIST_LOADING,
    WISH_LIST_SUCCESS
} from '../types/wishListTypes';
import i18n from '../../localization';
import { protectedAPI } from '../../helpers/api';

export const getWishlistData = async (dispatch: Dispatch) => {
    dispatch({ type: WISH_LIST_LOADING });
    const resData: any = await GetWishList();
    if (resData.status === 200) {
        dispatch({
            type: WISH_LIST_SUCCESS,
            payload: resData.data.wishLists
        });
    } else {
        dispatch({
            type: WISH_LIST_ERROR,
            payload: resData.message || 'something went wrong'
        });
    }
};
export const addToWishList = async (dispatch: Dispatch, _id: string) => {
    const resData: any = await AddWishList(_id);
    if (resData.status === 200) {
        message.success({
            content: i18n.t('addedToWishlist'),
            key: _id,
            duration: 2
        });
        getWishlistData(dispatch);
    }
};
export const removeFromWishList = async (dispatch: Dispatch, _id: string) => {
    const res: $FIXME = await RemoveWishList(_id);
    if (res.status === 200) {
        message.warning({
            content: i18n.t('removedFromWishlist'),
            key: _id
        });
        getWishlistData(dispatch);
    }
};
// api call happening here
const GetWishList = async () => {
    try {
        const result = await protectedAPI.get(`/${getWishList}`);
        return result;
    } catch (error) {
        return error;
    }
};

const AddWishList = async (_id: string) => {
    try {
        return await protectedAPI.post(`/${getWishList}`, {
            car: _id
        });
    } catch (error) {
        return error;
    }
};

const RemoveWishList = async (_id: string) => {
    try {
        return await protectedAPI.delete(`/${getWishList}/${_id}`);
    } catch (error) {
        return error;
    }
};
