import { GET_PRESS } from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { IPress } from '../../interface/press';

export interface IPressState {
    press: IPress[];
}

const initialState: IPressState = {
    press: []
};

const pressReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_PRESS:
            return {
                ...state,
                press: action.payload
            };

        default:
            return state;
    }
};

export default pressReducer;
