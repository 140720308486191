import { ALL_LANGUAGES } from "../../constants"
import heroArabic from "./arabic";
import getHeroEnglish from "./english";
import getHeroFrench from "./french";
import heroKinyarwanda from "./kinyarnwanda";
import heroXhosa from "./xhosa";
import heroZulu from "./zulu";
import heroWolof from "./wolof";
import { getTopLevelDomain } from "../../utils/translation";

const topLevelDomain = getTopLevelDomain();
// const auto24Domain = `AUTO24.${topLevelDomain}`;

const heroLang = {
    [ALL_LANGUAGES.english.value]: getHeroEnglish(topLevelDomain),  
    [ALL_LANGUAGES.french.value]: getHeroFrench(topLevelDomain), 
    [ALL_LANGUAGES.arabic.value]: heroArabic,  
    [ALL_LANGUAGES.zulu.value]: heroZulu, 
    [ALL_LANGUAGES.xhosa.value]: heroXhosa,
    [ALL_LANGUAGES.kinyarnwanda.value]: heroKinyarwanda, 
    [ALL_LANGUAGES.wolof.value]: heroWolof, 
}

export default heroLang;