import {
    FILTERED_CARS_FOOTER_ERROR,
    FILTERED_CARS_FOOTER_LOADING,
    FILTERED_CARS_FOOTER_SUCCESS,
    IFilteredCarsFooterContentType
} from '../types/filteredCarsFooterTypes';

const initialState: IFilteredCarsFooterContentType = {
    status: false,
    models: [],
    msg: null
};

const FilterCarsFooterReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case FILTERED_CARS_FOOTER_LOADING:
            return {
                ...state,
                loading: true
            };
        case FILTERED_CARS_FOOTER_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                status: data.status,
                msg: data.msg
            };
        case FILTERED_CARS_FOOTER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: data.status,
                msg: data.msg,
                models: data.payload
            };
        default:
            return state;
    }
};
export default FilterCarsFooterReducer;
