export const CAR_CERTIFICATION_LOADING = 'CAR_CERTIFICATION_LOADING'

export const CAR_CERTIFICATION_SUCCESS = 'CAR_CERTIFICATION_SUCCESS'

export const CAR_CERTIFICATION_ERROR = 'CAR_CERTIFICATION_ERROR'

export interface ICarCrtificationInitialType {
  loading: boolean
  errror: boolean
  carCertification: any
}

export interface ICarCertification {
  _id: string
  phoneNumber: string
  status: string
  mileage: string
  year: number
  model: string
  brand: string
}
