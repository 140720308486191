import { ActionType } from '../../interface/actionType';
import {
    GET_OUR_TEAM_BEGIN,
    GET_OUR_TEAM_FAILED,
    GET_OUR_TEAM_SUCCESS,
    IOurTeamInitialTypes
} from '../types/ourTeamTypes';

const initState: IOurTeamInitialTypes = {
    ourTeam: [],
    total: 0,
    loading: false,
    currentPage: null,
    pageLimit: null,
    error: null
};

const ourTeamReducer = (
    state = initState,
    action: ActionType
): IOurTeamInitialTypes => {
    let { type, payload } = action;

    switch (type) {
        case GET_OUR_TEAM_BEGIN:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_OUR_TEAM_SUCCESS:
            return {
                ...state,
                ...state,
                ourTeam: payload.teams,
                total: payload.total,
                currentPage: payload.currentPage,
                pageLimit: payload.pageLimit,
                loading: false
            };
        case GET_OUR_TEAM_FAILED:
            return {
                ...state,
                ourTeam: [],
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default ourTeamReducer;
