export const SERVICE_HISTORY_LOADING = 'SERVICE_HISTORY_LOADING'
export const SERVICE_HISTORY_SUCCESS = 'SERVICE_HISTORY_SUCCESS'
export const SERVICE_HISTORY_ERROR = 'SERVICE_HISTORY_ERROR'

export interface IServiceHistoryInitialType {
  loading: boolean
  error: boolean
  serviceHistory: Array<IServiceHistory>
}
export interface IServiceHistory {
  _id: string
  car: string
  date: string
  drivenKM: number
  serviceCenter: string
}
