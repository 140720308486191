export const FILTERED_CARS_FOOTER_LOADING = 'FILTERED_CARS_FOOTER_LOADING';
export const FILTERED_CARS_FOOTER_ERROR = 'FILTERED_CARS_FOOTER_ERROR';
export const FILTERED_CARS_FOOTER_SUCCESS = 'FILTERED_CARS_FOOTER_SUCCESS';

export interface IFilteredCarsFooterContentType {
    status: boolean;
    models: Model[];
    msg: string | null;
}

export interface Model {
    _id: string;
    slug: string;
    model: string;
    year?: number;
    brand: Brand;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export interface Brand {
    _id: string;
    brand: string;
    image: string;
    createdAt: string;
    updatedAt: string;
    slug: string;
    __v: number;
}
