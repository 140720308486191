import TokenService from '../../helpers/TokenService';
import { ActionType } from '../../interface/actionType';
import { AuthTypes, IUserInitialType } from '../types/AuthTypes';

export interface IAuthState {
    isloggedIn: boolean;
    showLoginModal: boolean;
    user: IUserInitialType;
}

const initialState: any = {
    isloggedIn: TokenService.getLocalAccessToken() ? true : false,
    showLoginModal: false,
    user: TokenService.getUser()
};

export const authReducer = (state = initialState, action: ActionType) => {
    const { type, payload } = action;
    switch (type) {
        case AuthTypes.LOG_IN:
            return {
                ...state,
                isloggedIn: true,
                user: payload.user
            };
        case AuthTypes.LOG_OUT:
            TokenService.removeLocalUser();
            return {
                showLoginModal: false,
                isloggedIn: false,
                user: null
            };
        case AuthTypes.UPDATE_USER:
            TokenService.setUser(payload.user);
            return {
                ...state,
                user: payload.user
            };
        case AuthTypes.SHOW_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: true
            };
        case AuthTypes.RESET_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: false
            };

        default:
            return state;
    }
};
