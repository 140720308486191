import { GET_COUNTRIES } from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { ICountry } from '../../interface/country';

export interface ICountryState {
    countries: ICountry[];
}

const initialState: ICountryState = {
    countries: []
};

const countryReducer = (
    state = initialState,
    action: ActionType
): ICountryState => {
    switch (action.type) {
        case GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };

        default:
            return state;
    }
};

export default countryReducer;
