import {
    APPEND_CLASS_TO_NAV
} from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';

export interface IAppendClassToNavState {
    appendClassToNav: boolean;
}

const initialState: IAppendClassToNavState = {
    appendClassToNav: false
};

const appendClassInNavbarReducer = (state = initialState, action: ActionType): IAppendClassToNavState => {
    switch (action.type) {
        case APPEND_CLASS_TO_NAV:
            return {
                ...state,
                appendClassToNav: action.payload
            };

        default:
            return state;
    }
};

export default appendClassInNavbarReducer;
