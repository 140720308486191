const metaKinyarwanda = {
    homePageTitle: "AUTO24.rw - Imodoka Zikoreshwa zemerewe mu Rwanda | Gura no Kugurisha mu Rwanda",
    homePageDesc: "Isoko yawe yo mu Rwanda yo gurisha no gukomera imodoka zikoreshwa zemerewe. Reba ubusobanuro bw'ibyerekeye imodoka bwawe, gukomera neza no kugurisha cyane.",
    homePageKeywords: "Imodoka Zikoreshwa, Gura Imodoka Rwanda, Kugurisha Imodoka Rwanda, Isoko rya AUTO24 mu Rwanda, Imodoka z'ubwoko bwiza, Isoko rya Imodoka mu Rwanda",
    homePageTitleOg: "AUTO24.rw - Imodoka Zikoreshwa zemerewe mu Rwanda | Gura no Kugurisha mu Rwanda",
    homePageDescOg: "Isoko yawe yo mu Rwanda yo gurisha no gukomera imodoka zikoreshwa zemerewe. Reba ubusobanuro bw'ibyerekeye imodoka bwawe, gukomera neza no kugurisha cyane.",
    homePageKeywordsOg: "Imodoka Zikoreshwa, Gura Imodoka Rwanda, Kugurisha Imodoka Rwanda, Isoko rya AUTO24 mu Rwanda, Imodoka z'ubwoko bwiza, Isoko rya Imodoka mu Rwanda",
    buyPageTitle: "Ibinyabiziga byagurishijwe mu Rwanda | Bikuze ibiciro byiza ku binyabiziga uyu munsi - auto24.rw",
    buyPageDesc: "Reba imodoka zikoreshwa zemerewe zishoboka ku isoko rya AUTO24.rw . Dufasha kugira ngo uhite ubona imodoka izagufasha mu bucuruzi n'ubuzima bwawe mu Rwanda.",
    buyPageKeywords: "Gura Imodoka Rwanda, Imodoka Zikoreshwa Zemerewe, AUTO24 Rwanda, Kugura Imodoka, Imodoka Zishoboka",
    buyPageTitleOg: "Ibinyabiziga byagurishijwe mu Rwanda | Bikuze ibiciro byiza ku binyabiziga uyu munsi - auto24.rw",
    buyPageDescOg: "Reba imodoka zikoreshwa zemerewe zishoboka ku isoko rya AUTO24.rw . Dufasha kugira ngo uhite ubona imodoka izagufasha mu bucuruzi n'ubuzima bwawe mu Rwanda.",
    buyPageKeywordsOg: "Gura Imodoka Rwanda, Imodoka Zikoreshwa Zemerewe, AUTO24 Rwanda, Kugura Imodoka, Imodoka Zishoboka",
    sellPageTitle: "Gurisha Imodoka Yawe Byihuse kandi Byoroshye | Fata Amasezerano y'Amavuta byihuse uyu munsi - auto24.rw",
    sellPageDesc: "Urashaka kugurisha imodoka yawe? Fata amafaranga byihuse kuri auto24.rw! Hamwe n'uburyo bwihuse kandi budateye amakenga, ushobora kugurisha imodoka yawe uyu munsi ukabona amafaranga vuba. Ntutinze—reba agaciro k'imodoka yawe ubu!",
    sellPageKeywords: "Kugurisha Imodoka Rwanda, Kugurisha Imodoka Zikoreshwa, AUTO24 Kugurisha, Ibisobanuro by'Imodoka",
    sellPageTitleOg: "Gurisha Imodoka Yawe Byihuse kandi Byoroshye | Fata Amasezerano y'Amavuta byihuse uyu munsi - auto24.rw",
    sellPageDescOg: "Urashaka kugurisha imodoka yawe? Fata amafaranga byihuse kuri auto24.rw! Hamwe n'uburyo bwihuse kandi budateye amakenga, ushobora kugurisha imodoka yawe uyu munsi ukabona amafaranga vuba. Ntutinze—reba agaciro k'imodoka yawe ubu!",
    sellPageKeywordsOg: "Kugurisha Imodoka Rwanda, Kugurisha Imodoka Zikoreshwa, AUTO24 Kugurisha, Ibisobanuro by'Imodoka",
    aboutPageTitle: "Ku Ibisobanuro bya AUTO24.rw - Isoko ry'Imodoka Zikoreshwa Zemerewe Iyobewe mu Rwanda",
    aboutPageDesc: "Menya ibisobanuro bya AUTO24.rw , isoko rya imodoka zikoreshwa zemerewe ryiyobowe mu Rwanda. Dufasha kugurisha imodoka z'ubwoko bwiza.",
    aboutPageKeywords: "Ibisobanuro bya AUTO24 Rwanda, Isoko ry'Imodoka Zikoreshwa, Isoko ry'Imodoka Riyobowe, Isoko ry'Imodoka mu Rwanda",
    aboutPageTitleOg: "Ku Ibisobanuro bya AUTO24.rw - Isoko ry'Imodoka Zikoreshwa Zemerewe Iyobewe mu Rwanda",
    aboutPageDescOg: "Menya ibisobanuro bya AUTO24.rw , isoko rya imodoka zikoreshwa zemerewe ryiyobowe mu Rwanda. Dufasha kugurisha imodoka z'ubwoko bwiza.",
    aboutPageKeywordsOg: "Ibisobanuro bya AUTO24 Rwanda, Isoko ry'Imodoka Zikoreshwa, Isoko ry'Imodoka Riyobowe, Isoko ry'Imodoka mu Rwanda",
    financePageTitle: "Finansiyo y'Imodoka Yoroheje | 100% ku mbuga & Amategeko y'Ubwiza - auto24.rw",
    financePageDesc: "Reba uburyo bwo kubona amafaranga y'imodoka bitagoranye kuri Auto24.rw - 100% ku mbuga, amategeko y'ubwiza n'ibihe byoroshye. Fata imodoka yawe y'ikitegererezo uyu munsi!",
    financePageKeywords: "Amansuzi yo Gura Imodoka, AUTO24 Amansuzi, Amansuzi Zishoboka",
    financePageTitleOg: "Finansiyo y'Imodoka Yoroheje | 100% ku mbuga & Amategeko y'Ubwiza - auto24.rw",
    financePageDescOg: "Reba uburyo bwo kubona amafaranga y'imodoka bitagoranye kuri Auto24.rw - 100% ku mbuga, amategeko y'ubwiza n'ibihe byoroshye. Fata imodoka yawe y'ikitegererezo uyu munsi!",
    financePageKeywordsOg: "Amansuzi yo Gura Imodoka, AUTO24 Amansuzi, Amansuzi Zishoboka",
    insurePageTitle: "Ibiranga Icyizere mu Bicuruzwa by'Ubwishingizi, Gukora mu Gahunda nziza ku Giciro cyiza - auto24.rw",
    insurePageDesc: "Fata politiki y'ubwishingizi bwiza ku giciro cyiza hamwe na Auto24.rw. Turakora ibikorwa by’ubwishingizi bitagoranye byateguwe ku buryo bikwiranye nawe - kurinda imodoka yawe uyu munsi!",
    insurePageKeywords: "Umutekano w'Imodoka, AUTO24 Umutekano, Umutekano mu Rwanda",
    insurePageTitleOg: "Ibiranga Icyizere mu Bicuruzwa by'Ubwishingizi, Gukora mu Gahunda nziza ku Giciro cyiza - auto24.rw",
    insurePageDescOg: "Fata politiki y'ubwishingizi bwiza ku giciro cyiza hamwe na Auto24.rw. Turakora ibikorwa by’ubwishingizi bitagoranye byateguwe ku buryo bikwiranye nawe - kurinda imodoka yawe uyu munsi!",
    insurePageKeywordsOg: "Umutekano w'Imodoka, AUTO24 Umutekano, Umutekano mu Rwanda",
    blogPageTitle: "Inama z'Ubwiza bw'Imodoka no Kumenya Amakuru y'Uruganda rw'Ibinyabiziga - auto24.rw",
    blogPageDesc: "Sura auto24.rw kugira ngo ubone inama z'abahanga mu kwita ku binyabiziga, amakuru ku bigenzi ndetse no kumenya amakuru mashya y’ibinyabiziga. Gumana amakuru yo kubungabunga imodoka no kuzamura urwego rw'ubumenyi bw’ibinyabiziga!",
    blogPageKeywords: "AUTO24 Inkuru, Amakuru y'Imodoka, Amakuru yo Ku Rwanda, Amakuru y'Ibisobanuro",
    blogPageTitleOg: "Inama z'Ubwiza bw'Imodoka no Kumenya Amakuru y'Uruganda rw'Ibinyabiziga - auto24.rw",
    blogPageDescOg: "Sura auto24.rw kugira ngo ubone inama z'abahanga mu kwita ku binyabiziga, amakuru ku bigenzi ndetse no kumenya amakuru mashya y’ibinyabiziga. Gumana amakuru yo kubungabunga imodoka no kuzamura urwego rw'ubumenyi bw’ibinyabiziga!",
    blogPageKeywordsOg: "AUTO24 Inkuru, Amakuru y'Imodoka, Amakuru yo Ku Rwanda, Amakuru y'Ibisobanuro",
    qNaPageTitle: "AUTO24 - Frequently asked questions ",
    contactUsPageTitle: " AUTO24 - Contact us",
    privacyPolicyPageTitle: " AUTO24 - Privacy policy",
    termsNusePageTitle: "AUTO24 - Terms & conditions",
    carDetailPageTitle: "Buy {{make}},{{price}} RWF - AUTO24",
    carDetailPageDesc: "Buy a {{make}} certified used car at {{PRIX}} RWF with AUTO24, Rwanda",
    buyPageDescWhenBrand: "Best prices and services for {{BRAND}} used cars in Rwanda. Refund policy, warranty, insurance, maintenance, and all included!"
}

export default metaKinyarwanda;