export const FETCH_PAGES_CONTENT_LOADING = 'FETCH_PAGES_CONTENT_LOADING';
export const FETCH_PAGES_CONTENT_SUCCESS = 'FETCH_PAGES_CONTENT_SUCCESS';
export const FETCH_PAGES_CONTENT_ERROR = 'FETCH_PAGES_CONTENT_ERROR';
export const RESET_PAGES_CONTENT_SUCCESS = 'RESET_PAGES_CONTENT_SUCCESS';

export interface IPagesInitialType {
    loading: boolean;
    error: boolean;
    pages: Array<IPagesType>;
}

export interface IPageDetails {
    body: any;
    lang: string;
    title: string;
    _id?: string
}

export interface IPagesType {
    _id: string;
    title: string;
    body?: string | any;
    slug: string;
    page?: string;
    status: boolean;
    details?: IPageDetails[];
}