import {
    FETCH_FINANCING_LOADING,
    FETCH_FINANCING_ERROR,
    FETCH_FINANCING_SUCCESS,
    IFinancingInitialType
} from '../types/financingTypes';

const initialState: IFinancingInitialType = {
    loading: false,
    error: false,
    financing: []
};

const financingReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case FETCH_FINANCING_LOADING:
            return {
                ...state,
                loading: true
            };
        case FETCH_FINANCING_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };
        case FETCH_FINANCING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                financing: data.payload
            };
        default:
            return state;
    }
};
export default financingReducer;
