import {
    SUBSCRIBE_ERROR,
    SUBSCRIBE_LOADING,
    SUBSCRIBE_SUCCESS
} from '../types/subscribeTypes';

const initialState = {
    loading: false,
    error: false,
    subscribers: [],
    email: ''
};

const subscribeReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case SUBSCRIBE_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case SUBSCRIBE_SUCCESS:
            return {
                ...state,
                loading: false,
                erorr: false
            };
        case SUBSCRIBE_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};
export default subscribeReducer;
