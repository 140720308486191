import {
    FETCH_CAR_AVAILABLE_BRAND,
    FETCH_CAR_BRANDS_ERROR,
    FETCH_CAR_BRANDS_LOADING,
    FETCH_CAR_BRANDS_SUCCESS,
    ICarBrandsInitialType
} from '../types/carBrandsTypes';

const initialState: ICarBrandsInitialType = {
    loading: false,
    error: false,
    carBrands: [],
    carAvailableBrands: []
};

const carBrandsReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case FETCH_CAR_BRANDS_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case FETCH_CAR_BRANDS_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };
        case FETCH_CAR_BRANDS_SUCCESS:
            return {
                ...state,
                laoding: false,
                error: false,
                carBrands: data.payload
            };
        case FETCH_CAR_AVAILABLE_BRAND:
            return {
                ...state,
                laoding: false,
                error: false,
                carAvailableBrands: data.payload
            };

        default:
            return state;
    }
};
export default carBrandsReducer;
