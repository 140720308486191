import {
    CREATE_SELL_CAR_ERROR,
    CREATE_SELL_CAR_LOADING,
    CREATE_SELL_CAR_SUCCESS,
    UPDATE_FORM_DATA
} from '../types/sellCarTypes';

const initialState = {
    loadingSellData: false,
    error: false,
    sellingCars: [],
    formData: {}
};

const sellingCarsReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case CREATE_SELL_CAR_LOADING:
            return {
                ...state,
                loadingSellData: true,
                error: false
            };
        case CREATE_SELL_CAR_SUCCESS:
            return {
                ...state,
                loadingSellData: false,
                error: false
            };
        case CREATE_SELL_CAR_ERROR:
            return {
                ...state,
                loadingSellData: false,
                error: true
            };
        case UPDATE_FORM_DATA:
            return {
                ...state,
                formData: data.payload
            };

        default:
            return state;
    }
};
export default sellingCarsReducer;
