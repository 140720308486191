export const FOOTER_CONTENT_LOADING = 'FOOTER_CONTENT_LOADING';
export const FOOTER_CONTENT_ERROR = 'FOOTER_CONTENT_ERROR';
export const FOOTER_CONTENT_SUCCESS = 'FOOTER_CONTENT_SUCCESS';

export interface IFooterContentType {
    loading: boolean;
    error: boolean | null;
    footerContent: {
        details: Array<IFooterContentDetails>;
    } | null;
}

export interface IFooterContentDetails {
    description: string;
    lang: string;
    sub_title: string;
    title: string;
}
