import {
    FOOTER_CONTENT_SUCCESS,
    FOOTER_CONTENT_LOADING,
    FOOTER_CONTENT_ERROR,
    IFooterContentType
} from '../types/footerContentTypes';

const initialState: IFooterContentType = {
    loading: false,
    error: null,
    footerContent: null
};

const FooterContentReducer = (state: any = initialState, data: any) => {
    switch (data.type) {
        case FOOTER_CONTENT_LOADING:
            return {
                ...state,
                loading: true
            };
        case FOOTER_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };
        case FOOTER_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                footerContent: data.payload
            };
        default:
            return state;
    }
};
export default FooterContentReducer;