export const CREATE_CONTACT_US_LOADING = 'CREATE_CONTACT_US_LOADING'
export const CREATE_CONTACT_US_ERROR = 'CREATE_CONTACT_US_ERROR'
export const CREATE_CONTACT_US_SUCCESS = 'CREATE_CONTACT_US_SUCCESS'

export interface IContactUsInitialType {
  loading: boolean
  error: boolean
  contactQuery: IContactQueryType
}
export interface IContactQueryType {
  _id: string
  fullName: string
  email: string
  phoneNumber: string
  message: string
}
