import {
  FETCH_PAGES_CONTENT_ERROR,
  FETCH_PAGES_CONTENT_LOADING,
  FETCH_PAGES_CONTENT_SUCCESS,
  IPagesInitialType,
  RESET_PAGES_CONTENT_SUCCESS,
} from '../types/pagesType'

const initialState: IPagesInitialType = {
  loading: false,
  error: false,
  pages: [],
}

const pagesReducer = (state: any = initialState, data: any) => {
  switch (data.type) {
    case FETCH_PAGES_CONTENT_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PAGES_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case FETCH_PAGES_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        pages: data.payload,
      }
    case RESET_PAGES_CONTENT_SUCCESS:
      return {
        ...initialState
      }
    default:
      return state
  }
}
export default pagesReducer
